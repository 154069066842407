@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.Reviews {
    text-align: center;
    margin: 0 auto;
    padding: 0;
    font-family: "Poppins", "sans-serif";
    background-color: #DDE5F8;
}

@media screen and (max-width: 700px) {
    .leftCard {
        display: none;
    }

    .rightCard {
        display: none;
    }

    .tracker {
        padding: 0;
        width: 70%;
    }

    .circle {
        padding: 0;
    }
}

.carousel-container {
    display: flex;
    flex-flow: column;
}

.avatars {
    border-radius: 50%;
    height: 100px;
    margin-top: -6em;
}

.cards {
    display: flex;
    width: 100%;
}

.card {
    margin: 5em 2em 0em;
    background-color: ivory;
    padding: 2em;
    width: 35%;
    height: 200px;
    flex: 1;
    /* each card is of equal size */
    box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
}

.leftCard {
    margin-right: -15em;
    margin-top: 7em;
}

.rightCard {
    margin-left: -15em;
    margin-top: 7em;
}

.active {
    z-index: 1;
    height: 250px;
    background-image: url("../Assets/quote-left.svg"), url("../Assets/quote-right.svg");
    background-repeat: no-repeat;
    background-position-x: 0, right;
    background-position-y: 0, bottom;
    background-size: 50px;
    background-origin: content-box;
}

.arrow-container {
    display: flex;
    justify-content: space-between;
    margin-top: -10em;
    position: relative;
}

.arrow {
    height: 3em;
}

.arrow:active {
    fill: lightgray;
}

.name {
    margin-top: 0.5em;
    margin-bottom: -0.2em;
}

p {
    padding: 0em 3em;
}


.text {
    height: 90%;
    overflow-y: auto;
    /* Add this line for vertical scrolling on mobile */
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
}


.text p {
    padding: 0;
}

.tracker {
    display: flex;
    justify-content: center;
    height: 2.5em;
    margin-top: 1em;
}

.circle {
    transform: scale(0.3);
}