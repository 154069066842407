/* RegisterForm.css */

.register-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 80%;
    /* Set width to 80% of viewport width */
    max-width: 500px;
    height: 80%;
    /* Limit width for larger screens */
}

.register-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    color: #333;
    cursor: pointer;
    padding: 6px;
    background: none;
    border: none;
}

.close-btn:hover {
    color: black;
}

.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    /* Set a maximum height for the form */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
}

.register-form h2 {
    margin-bottom: 20px;
    color: #333;
}

.register-form input,
.register-form select {
    width: 100%;
    /* Set width to 100% */
    max-width: 80%;
    /* Limit maximum width for larger screens */
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.register-form button {
    width: 100%;
    /* Set width to 100% */
    max-width: 80%;
    /* Limit maximum width for larger screens */
    padding: 12px;
    background-color: #6a1b9a;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-form button:hover {
    background-color: #4a148c;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .register-modal {
        width: 90%;
        /* Set width to 90% of viewport width for smaller screens */
        max-width: 90%;
        /* Limit maximum width for smaller screens */
    }
}